import React, {Fragment, useEffect, useRef, useState} from 'react';
import {ChevronDownIcon, ChevronUpIcon, TrashIcon} from '@heroicons/react/solid'
import {classNames, sharedAccessTokenKey, sharedFetch} from "../helpers/helpers";
import {useLogout} from "react-admin";
import {Dialog, Transition} from '@headlessui/react'

function formatAddress(addressObject) {
    if (!addressObject) return null
    let address = ""
    if (addressObject.address) address += addressObject.address
    else if (addressObject.streetName) {
        address += addressObject.streetName + " "
        if (addressObject.streetNumber) address += addressObject.streetNumber
    }
    if (addressObject.postalCode) address += (address.length > 0 ? ", " : "") + addressObject.postalCode + " "
    if (address && addressObject.city) address += addressObject.city
    if (address && addressObject.country) address += ", " + addressObject.country
    return address
}

const defaultColumns = [
        /*{v: d => d.uid, label: "UID"},*/
        {
            v: d => d.email,
            label: "Email",
            href: d => "mailto:" + (d.profile?.email || d.email || "")
        },
        {v: d => d.profile?.status, label: "Status"},
        {v: d => d.emailVerified === true ? "Yes" : "-", label: "Email verified"},
        {v: d => d.hasCredentials === true ? "Yes" : "-", label: "Credentials"},
        {v: d => d.enrolledFactors && d.enrolledFactors.length > 0 ? "Yes" : "-", label: "2FA"},
        {v: d => d.lastSignedInAt, label: "Last signed in"},
    ],
    defaultOrderBy = "status",
    defaultOrderByDirection = "desc"

const limit = 10;

export const AdminUsersList = () => {
    const [columns, setColumns] = useState(defaultColumns)
    const [orderBy, setOrderBy] = useState(defaultOrderBy)
    const [orderByDirection, setOrderByDirection] = useState(defaultOrderByDirection)
    const [result, setResult] = useState({})
    const [offset, setOffset] = useState(0)
    const logout = useLogout();

    const [isCreateAdminDialogOpen, setIsCreateAdminDialogOpen] = useState(false)
    const cancelButtonRef = useRef(null)

    const [isFilterOn, setIsFilterOn] = useState(false)
    const [filterCount, setFilterCount] = useState(0)

    useEffect(() => {
        fetchList()
    }, [orderBy, orderByDirection, offset, isFilterOn])

    function fetchList(next = "") {
        sharedFetch(`admin/users/admin?limit=${limit}&orderBy=${orderBy}&orderByDirection=${orderByDirection}&offset=${offset}${isFilterOn ? "&filter=isProbablyOverdue" : ""}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey)
            }
        })
            .then((json) => {
                setResult(json)
            })
    }

    function nextPage(e) {
        //result?.offset + result?.count < result?.total
        if (result?.offset + result?.count < result?.total) setOffset(offset + limit)
    }

    function prevPage(e) {
        if (offset > 0) setOffset(offset - limit)
    }

    function sort(columnIndex) {
        return function onClick(e) {
            e.preventDefault()
            const col = columns[columnIndex]
            if (col?.sort) {
                switch (col.direction) {
                    case "asc":
                        col.direction = "desc"
                        break;
                    case "desc":
                        col.direction = null
                        break;
                    default:
                        col.direction = "asc"
                }
                if (col.direction) {
                    setOrderBy(col.sort)
                    setOrderByDirection(col.direction)
                } else {
                    setOrderBy(defaultOrderBy)
                    setOrderByDirection(defaultOrderByDirection)
                }
                setColumns(columns.map((it, i) => i === columnIndex ? {...col} : {...it, direction: null}))
                setOffset(0)
            }
        }
    }

    async function deleteAdmin(data) {
        console.log(data)
        if (!window.confirm("Are you sure you want to delete this admin?")) return;


        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
        }

        await sharedFetch(`admin/users/admin/${data.uid}`, {
            method: 'DELETE',
            headers
            //headers, body: JSON.stringify({invoices: chunk})
        }).catch(e => {
            if (e?.message) {
                alert("Error updating invoices: " + e.message)
            } else {
                alert("There was an error updating invoices!")
            }
            throw e
        }).finally(() => {

            fetchList()
        })
    }

    async function createAdmin(e) {
        e.preventDefault();

        const form = e.target;
        const data = new FormData(form);

        const body = {
            email: data.get("email"),
            password: data.get("password"),
            phone: data.get("phone"),
        }
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem(sharedAccessTokenKey),
        }

        setIsCreateAdminDialogOpen(false)

        await sharedFetch(`admin/users/admin`, {
            method: 'POST',
            headers,
            body: JSON.stringify(body)
            //headers, body: JSON.stringify({invoices: chunk})
        }).catch(e => {
            if (e?.message) {
                alert("Error updating invoices: " + e.message)
            } else {
                alert("There was an error updating invoices!")
            }
            throw e
        }).finally(() => {

            fetchList()
        })
    }

    return (
        <>
            <div className="my-8 space-y-6">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="inline-flex text-xl font-semibold text-gray-900">Admins</h1>
                            {/*<p className="mt-2 text-sm text-gray-700">
                                A list of all the users in your account including their name, title, email and role.
                            </p>*/}
                            <div className="sm:px-8 inline-flex">
                                {/*<button
                                    type="button"
                                    onClick={toggleFilter}
                                    className={classNames(
                                        "inline-flex rounded-full items-center py-1 text-sm font-medium box-border border-2",
                                        isFilterOn ? "pl-1 pr-1 border-current" : "pr-2.5 pl-1 border-transparent",
                                        filterCount > 0 ? "bg-red-100 text-red-700" : "bg-gray-100 text-gray-700"
                                    )}>
                                    <div
                                        className={classNames(
                                            "flex-shrink-0 mr-1 h-5 rounded-full inline-flex items-center justify-center",
                                            filterCount > 0 ? "text-red-400 bg-red-50" : "text-gray-400 bg-gray-50"
                                        )}
                                    >
                                        <span className="sr-only">Filter count</span>
                                        <span className="px-1">{filterCount}</span>
                                    </div>
                                    Filtered
                                </button>*/}
                            </div>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <button
                                type="button"
                                onClick={() => setIsCreateAdminDialogOpen(true)}
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                            >
                                Create new
                            </button>
                            {/*<a
                                type="button"
                                href="#/shared-users/create"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                            >
                                Create new
                            </a>*/}
                        </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            {columns.map((col, i) => (
                                                <th key={col.label} scope="col"
                                                    className={classNames(
                                                        "text-left text-sm font-semibold text-gray-900",
                                                        i === 0 ? "py-3.5 pl-4 pr-3 sm:pl-6" : "px-3 py-3.5"
                                                    )}>
                                                    <span onClick={sort(i)} className={classNames(
                                                        "group inline-flex truncate",
                                                        col.sort ? "cursor-pointer" : "cursor-default"
                                                    )}>
                                                        {col.label}
                                                        <span className={classNames(
                                                            col.direction ? "ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300" : "invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                                        )}>{col.direction === "asc" ? (
                                                            <ChevronUpIcon className="h-5 w-5" aria-hidden="true"/>
                                                        ) : col.direction === "desc" ? (
                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true"/>
                                                        ) : null}</span>
                                                    </span>
                                                </th>
                                            ))}
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                        {result?.results?.map((data, index) => (
                                            <tr key={index}>
                                                {columns.map((col, i) => (
                                                    <td key={col.label}
                                                        className={classNames(
                                                            i === 0 ? "py-4 pl-4 pr-3 font-medium text-gray-900 sm:pl-6" : "px-3 py-4 text-gray-500"
                                                        )}>
                                                        {col.href ? (
                                                            <a
                                                                className={classNames(
                                                                    col.className || "",
                                                                    "p-0 whitespace-nowrap text-sm truncate hover:text-gray-900 underline",
                                                                )}
                                                                rel="noreferrer"
                                                                target="_blank"
                                                                href={col.href(data)}>
                                                                {col.v(data)}
                                                            </a>
                                                        ) : (
                                                            <span
                                                                className={classNames(
                                                                    col.className || "",
                                                                    "p-0 whitespace-nowrap text-sm truncate",
                                                                )}>
                                                                {col.v(data)}
                                                            </span>
                                                        )}
                                                    </td>
                                                ))}
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                    <button
                                                        onClick={() => deleteAdmin(data)}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <TrashIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </button>
                                                    {/*
                                                    <a
                                                        href={"#/shared-users/" + data.uid}
                                                        className="ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <PencilIcon className="h-3 w-3" aria-hidden="true"/>
                                                    </a>*/}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>

                                    <nav
                                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                        aria-label="Pagination"
                                    >
                                        <div className="hidden sm:block">
                                            <p className="text-sm text-gray-700">
                                                Showing <span
                                                className="font-medium">{(result?.offset + 1) || "0"}</span> to <span
                                                className="font-medium">{result?.offset + result?.count || "0"}</span> results
                                            </p>
                                        </div>
                                        <div className="flex-1 flex justify-between sm:justify-end">
                                            <button
                                                type="button"
                                                onClick={prevPage}
                                                className={classNames(
                                                    "relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                    result?.offset ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                )}
                                            >
                                                Previous
                                            </button>
                                            <button
                                                type="button"
                                                onClick={nextPage}
                                                className={classNames(
                                                    "ml-3 relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md",
                                                    (result?.offset + result?.count < result?.total) ? "text-gray-700 bg-white hover:bg-gray-50 border-gray-300" : "text-gray-300 bg-white border-gray-100"
                                                )}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Transition.Root show={isCreateAdminDialogOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef}
                        onClose={setIsCreateAdminDialogOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div
                            className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <form onSubmit={createAdmin}>
                                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">

                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title as="h3"
                                                              className="text-base font-semibold leading-6 text-gray-900">
                                                    Create admin
                                                </Dialog.Title>
                                                <div className="mt-2 mb-4">
                                                    <div className="space-y-12">


                                                        <div
                                                            className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                                            <div className="sm:col-span-4">
                                                                <label htmlFor="phone"
                                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                                    Phone (for 2FA)
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        id="phone"
                                                                        name="phone"
                                                                        type="phone"
                                                                        autoComplete="phone"
                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="sm:col-span-4">
                                                                <label htmlFor="email"
                                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                                    Email
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        id="email"
                                                                        name="email"
                                                                        type="email"
                                                                        autoComplete="email"
                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="sm:col-span-4">
                                                                <label htmlFor="password"
                                                                       className="block text-sm font-medium leading-6 text-gray-900">
                                                                    Password
                                                                </label>
                                                                <div className="mt-2">
                                                                    <input
                                                                        id="password"
                                                                        name="password"
                                                                        type="password"
                                                                        autoComplete="new-password"
                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <button
                                                type="submit"
                                                className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
                                                onClick={() => setIsCreateAdminDialogOpen(false)}
                                            >
                                                Submit
                                            </button>
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                onClick={() => setIsCreateAdminDialogOpen(false)}
                                                ref={cancelButtonRef}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
};
